.playlist_detail_view_wrap .popup_contents {
    padding-top: 25px;
    min-height: 450px;
}

.playlist_detail_view_wrap .ui-dialog-content {
    padding-bottom: 30px;
}

.playlist_imgview > .preview_wrap {
    position: relative;
    width: 370px;
    overflow: hidden;
    background-color: #E4E4E4;
}

.playlist_imgview > div > .slide_img_wrap {
    width:370px;
    height:220px;
    overflow:hidden;
    background-color:#E4E4E4;
    text-align:center;
    line-height:220px;
}

.playlist_imgview_thumb_wrap {
    background-color: rgb(228, 228, 228);
    width: 100px;
    height: 56px;
    line-height: 56px;
    text-align: center;
}

.playlist_imgview_thumb_wrap .linked {
    background: url('../../../images/icon/icon_share.png') no-repeat;
    background-size: 20px 20px;
    z-index: 1;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 5px;
    bottom: 5px;
}

.playlist_detail_view_wrap .playlist_imgview > .preview_wrap.landscape {
    width: 370px;
    height: 220px;
    background-color: #E4E4E4;
    text-align: center;
}

.playlist_detail_view_wrap .playlist_imgview ul li {
    width: 100px;
    height: 56px;
    background: #E4E4E4;
    margin-right: 8px;
    line-height: 56px;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.playlist_detail_view_wrap .playlist_imgview ul li:last-child {
    margin-right: 0;
}

.playlist_detail_view_wrap .detail_view table tr.bullet_line td {
    padding: 2px 0 1px 0;
    color: #474747;
}

.playlist_detail_view_wrap .detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.playlist_detail_view_wrap .sub_tabwrap.h42 {
    height: 42px;
}

.playlist_detail_view_wrap .ml25 {
    margin-left: 25px !important;
}

.playlist_detail_view_wrap .mt25 {
    margin-top: 25px !important;
}

.playlist_detail_view_wrap .mt9 {
    margin-top: 9px !important;
}

.playlist_detail_view_wrap .mt46 {
    margin-top: 46px !important;
}

.playlist_detail_view_wrap .none_scrolling {
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    font-size: 13px;
}

.playlist_detail_view_wrap .none_scrolling.detail_table {
    font-size: 12px;
}

.playlist_detail_view_wrap .none_scrolling.scrollheight250 {
    max-height: 250px;
    overflow-y: auto;
}

.playlist_detail_view_wrap .none_scrolling table tr th {
    background: #fafafa;
    border-right: 1px solid #f0f0f0;
    line-height: 30px;
    padding-left: 12px;
}

.playlist_detail_view_wrap .none_scrolling table tr th:first-child {
    padding-left: 14px;
}

.playlist_detail_view_wrap .none_scrolling table tr th:last-child {
    border-right: 0;
}

.playlist_detail_view_wrap .none_scrolling.detail_table table tr th {
    line-height: 16px;
    vertical-align: middle;
    padding: 10px;
}

.playlist_detail_view_wrap .none_scrolling.detail_table.nodata table tr th {
    color: #d0d0d0;
}

.playlist_detail_view_wrap .none_scrolling table tr td {
    line-height: 15px;
    vertical-align: middle;
    color: #797979 !important;
    padding: 12px;
}

.playlist_detail_view_wrap .none_scrolling.pdnone table tr td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.playlist_detail_view_wrap .none_scrolling.detail_table table tr td {
    line-height: 20px;
    vertical-align: middle;
    padding: 10px;
}

.playlist_detail_view_wrap .none_scrolling.detail_table.nodata table tr td {
    text-align: center;
    color: #c1c1c1;
}

.playlist_detail_view_wrap .none_scrolling table tr td img {
    vertical-align: middle;
}

.playlist_detail_view_wrap .none_scrolling table tr td:first-child {
    padding-left: 14px;
}

.playlist_detail_view_wrap .none_scrolling.bbline table tr td {
    border-bottom: 1px solid #f2f2f2;
    line-height: 37px;
}

.playlist_detail_view_wrap .none_scrolling.bbline table tr:last-child td {
    border-bottom: 0;
}

.playlist_detail_view_wrap .none_scrolling tr.time_detail td {
    padding: 0 0 0 0 !important;
}

.playlist_detail_view_wrap .none_scrolling tr.on td {
    background: #e6f2ff;
    color: #000 !important;
}

.playlist_detail_view_wrap .none_scrolling tr th label,
.playlist_detail_view_wrap .none_scrolling tr td label {
    text-indent: -9999px;
    display: inline;
}

.playlist_detail_view_wrap .none_scrolling table tr.on,
.playlist_detail_view_wrap .none_scrolling table tr:active td {
    background: #e1eefb;
    color: #2a2a2a;
}

.playlist_detail_view_wrap .none_scrolling table tr:hover td {
    background: #f3f9ff;
}

.playlist_detail_view_wrap .none_scrolling table tr.timer_line_info:hover td {
    cursor: pointer;
}

.playlist_detail_view_wrap .detail_table_title {
    font-size: 12px;
    display: inline-block;
    color: #686b72;
}

.playlist_detail_view_wrap .none_scrolling.check_list table tr th {
    border-bottom: 1px solid #f0f0f0;
}

.playlist_detail_view_wrap .none_scrolling.check_list table tr td {
    border-bottom: 1px solid #f0f0f0;
}

.playlist_detail_view_wrap .none_scrolling.check_list table tr:last-child td {
    border-bottom: 0;
}

.playlist_detail_view_wrap .detail_view {
    padding-top: 0px !important;
    position: relative;
}

.playlist_detail_view_wrap .detail_view.t_line {
    border-top: 1px solid #f4f4f4;
}

.playlist_detail_view_wrap .detail_view table {
    width: 100%;
}

.playlist_detail_view_wrap .detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.playlist_detail_view_wrap .detail_view.user_info table th {
    font-size: 13px;
}

.playlist_detail_view_wrap .detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.playlist_detail_view_wrap .detail_view table tr.bullet_line td {
    padding: 2px 0 1px 0;
    color: #474747;
}

.playlist_detail_view_wrap .detail_view table tr.va_t th {
    vertical-align: top;
    padding-top: 11px;
}

.playlist_detail_view_wrap .detail_view table td label {
    color: #474747;
    display: inline-block;
}

.playlist_detail_view_wrap .detail_view table td.icon img {
    vertical-align: middle;
    margin-right: 4px;
}

.playlist_detail_view_wrap .detail_view table th img {
    margin-left: 6px;
    vertical-align: middle;
}

.playlist_detail_view_wrap .detail_view table th .bull {
    display: inline-block;
    background: url('../../../images/icon/bullet_3.png') no-repeat 0 6px;
    padding-left: 13px;
}

.playlist_detail_view_wrap .detail_view table th h4 {
    margin: 0;
    font-size: 16px;
    color: #676767;
    font-weight: normal;
    margin-bottom: 10px;
}

.playlist_detail_view_wrap .detail_view table th h4 > span {
    color: #b8b8b8;
    font-size: 11px;
    display: inline-block;
    padding-left: 30px;
    background: url('../../../images/icon/bullet_2.png') no-repeat 17px;
}

.playlist_detail_view_wrap .detail_view .space {
    display: block;
}

.playlist_detail_view_wrap .detail_view .speed_controler {
    margin-top: 0;
}

.playlist_detail_view_wrap .detail_view .speed_controler > span {
    width: 273px;
}

.playlist_detail_view_wrap .detail_view .speed_controler > span > .txt {
    font-size: 10px;
    top: 20px;
    background: none;
    color: #b3b4b5;
}

.playlist_detail_view_wrap .detail_view.tline {
    border-top: 1px solid #f5f5f5;
}

.playlist_detail_view_wrap .detail_view table td .txt {
    color: #8d8d8d;
    font-size: 12px;
    display: block;
    line-height: 17px;
}

.playlist_detail_view_wrap .detail_view table tr.click_layer > td {
    position: relative;
    height: 115px;
}

.playlist_detail_view_wrap .detail_view table tr.click_layer td div {
    position: absolute;
    left: 355px;
    top: -38px;
}

.playlist_detail_view_wrap .detail_view table tr.click_layer td div table tr th {
    padding-left: 20px;
    color: #474747;
}

.playlist_detail_view_wrap .detail_view table tr.click_layer td div table tr:first-child th {
    background: url('../../../images/bg/bar_5.png') no-repeat 0 14px;
    padding-left: 20px;
}

.playlist_detail_view_wrap .detail_view table th a {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    background: url('../../../images/img/qu_notice.png') no-repeat;
}

.playlist_detail_view_wrap .detail_view table th a:hover {
    background: url('../../../images/img/qu_notice2.png') no-repeat;
}

.playlist_detail_view_wrap .pop_list {
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    border-bottom-left-radius: 2px;
    fonts-size: 13px;
    overflow-x: hidden;
    overflow-y: auto;
}
/*.detail_view .pop_list::-webkit-scrollbar {width:16px;}*/
.playlist_detail_view_wrap .pop_list.h372 {
    height: 372px;
}
.playlist_detail_view_wrap .pop_list.h372.border_none {
    border: none;
}
.playlist_detail_view_wrap .pop_list ul {
    top: 0px;
    left: 0px;
    z-index: 1;
}
.playlist_detail_view_wrap .pop_list ul li {
    color: #797979;
    vertical-align: middle;
    padding: 5px 0px 0px 5px;
}
/* .pop_list ul li:first-child {padding:7px 0 7px 11px;}
.pop_list ul li:last-child {border-right:0;} */
.playlist_detail_view_wrap .pop_list ul li.icon:hover {
    background: #f3f3f3;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}
.playlist_detail_view_wrap .pop_list ul li.icon:active {
    background: #f0f0f0;
}
.playlist_detail_view_wrap .pop_list ul li.icon > a {
    display: none;
}
.playlist_detail_view_wrap .pop_list ul li.icon:hover > a {
    display: block;
    width: 15px;
    height: 15px;
    float: right;
    margin-right: 0px;
    padding: 0;
    background: url('../../../images/img/list_arrow.png') no-repeat 5px 7px;
}

.playlist_detail_view_wrap .pop_list table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
    color: #797979;
}
/* .pop_list table tr td:first-child {border-right: 1px solid #f2f2f2; border-right:0;padding:7px 0 7px 11px;} */
.playlist_detail_view_wrap .pop_list table tr:last-child td {
    border-bottom: 1px solid #f2f2f2;
}
.playlist_detail_view_wrap .pop_list table tr:hover td {
    background: #f3f9ff;
    border-bottom: 1px solid #f3f9ff;
}
.playlist_detail_view_wrap .pop_list table tr td.icon img {
    margin-right: 4px;
}
.playlist_detail_view_wrap .pop_list table tr.on td,
/* .pop_list table tr:active td {background:#e6f2ff;border-bottom:1px solid #dae6f2;color:#2a2a2a;} */
/* .pop_list table tr th:hover {background:#f3f9ff;border-bottom:1px solid #f3f9ff;}*/
/* .pop_list table tr th.on {background-color:#e6f2ff;} */

.playlist_detail_view_wrap .pop_list table tr td.on {
    background-color: #e6f2ff;
}
.playlist_detail_view_wrap .pop_list table tr td input[type='checkbox'] + label {
    height: 16px;
    background: url('../../../images/img/checkbox_none.png') no-repeat;
    vertical-align: middle;
}
.playlist_detail_view_wrap .pop_list table tr:hover td input[type='checkbox'] + label {
    background: url('../../../images/img/checkbox_normal.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list table tr td input[type='checkbox']:hover + label {
    background: url('../../../images/img/checkbox_hover.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list table tr:active td input[type='checkbox'] + label {
    background: url('../../../images/img/checkbox_checked.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list table tr.on td input[type='checkbox'] + label {
    background: url('../../../images/img/checkbox_checked.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list table tr td input[type='checkbox'].on + label {
    background: url('../../../images/img/checkbox_checked.png') no-repeat;
}
/*.pop_list table tr th input[type="checkbox"]:checked + label {background:url('..../images/img/checkbox_checked.png') no-repeat;}
.pop_list table tr.on td input[type="checkbox"]:checked:hover + label {background:url('..../images/img/checkbox_checked.png') no-repeat;}*/
.playlist_detail_view_wrap .pop_list table tr.on td input[type='checkbox'].on + label {
    background: url('../../../images/img/checkbox_checked.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list td.thum .thumb_wrap {
    position: relative;
    width: 100px;
    height: 56px;
}

.playlist_detail_view_wrap .pop_list td.thum .thumb_wrap .play {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 30px;
    height: 32px;
    border: 0;
    background: url('../../../images/button/play.png') no-repeat;
}
.playlist_detail_view_wrap .pop_list table tr th label {
    text-indent: -9999px;
}
.playlist_detail_view_wrap .pop_list table tr th.checkall label {
    text-indent: 0px;
}
.playlist_detail_view_wrap .pop_list table tr td label {
    text-indent: -9999px;
}
.playlist_detail_view_wrap .pop_list table tr th.ch_label_use label {
    text-indent: 0px !important;
}
.playlist_detail_view_wrap .pop_list table tr th > .data_name > label {
    text-indent: 0px !important;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 50px;
    text-overflow: ellipsis;
    font-size: 10px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.playlist_detail_view_wrap .pop_list table tr th > .serviceName {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 90px;
    text-overflow: ellipsis;
}
.playlist_detail_view_wrap .pop_list table tr td.va_t {
    vertical-align: top;
    padding-top: 13px;
}
.playlist_detail_view_wrap .pop_list table tr th:first-child {
    padding: 7px 0 7px 11px;
}
/*.pop_list table tr th {background:#fafafa;border-right:1px solid #f0f0f0;color:#797979;vertical-align:middle;padding:7px 10px 7px 10px;border-top:1px solid #e7e7e7;border-bottom:1px solid #fafafa;}*/
.playlist_detail_view_wrap .pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url('../../../images/bg/table_divider.png') no-repeat right #eeeeef;
}
