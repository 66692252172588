.device-fault {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
}

.device-fault-chart {
    width: 100%;
    height: 152px;
}

.device-fault-guide {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: 0;
}

.device-fault-guide-color-index {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    margin-top: 5px;
}

.device-fault-guide-title-index {
    margin-left: 4px;
    font-size: 10px;
    color: #818181;
}

.device-fault-label {
    position: relative;
    width: 100%;
    height: 16px;
}

.device-fault-label-ago {
    position: absolute;
    left: 25px;
    font-size: smaller;
}

.device-fault-label-current {
    position: absolute;
    right: 0;
    font-size: smaller;
}