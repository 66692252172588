li {
    list-style: none;
}
.gnb {
    background: #232832;
    width: 94px;
    height: 100%;
    font-size: 11px;
    position: absolute;
    z-index: 0;
}
.gnb > ul {
    padding: 0;
    margin: 30px 0 0 0;
}
.gnb > ul > li {
    height: 65px;
    padding-top: 17px;
}
.gnb > ul > li.on {
    background-image: url(../../images/img/menu_selected_bg.png);
    background-position: 0px -5px;
}
.gnb > ul > li.on > a {
    color: #fff;
}
.logo {
    display: inline-block;
    margin-top: 28px;
    margin-left: 27px;
}