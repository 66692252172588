.checkboxWrap input[type="checkbox"] {width:0;height:0;margin:0;display:none;/*position:absolute;/* top:-9999px; */}
.checkboxWrap input[type="checkbox"] + label {padding-left:24px;color:#59616b;background:url('../../images/img/checkbox_normal.png') no-repeat;padding-bottom:3px;cursor:pointer;height: 16px;}
.checkboxWrap input[type="checkbox"] + label:hover {background:url('../../images/img/checkbox_hover.png') no-repeat;}
.checkboxWrap input[type="checkbox"]:disabled + label:hover {background:url('../../images/img/checkbox_disable.png') no-repeat;cursor: default;}
.checkboxWrap input[type="checkbox"]:disabled {background:url('../../images/img/checkbox_disable.png') no-repeat;cursor: default;border:1px solid #000;}
.checkboxWrap input[type="checkbox"]:checked + label {background:url('../../images/img/checkbox_checked.png') no-repeat;}
.checkboxWrap input[type="checkbox"]:checked:disabled + label {background:url('../../images/img/checkbox_checked_disabled.png') no-repeat;}
.checkboxWrap  input[type="checkbox"]:checked:disabled + label:hover {background:url('../../images/img/checkbox_checked.png') no-repeat;cursor: default;}
.checkboxWrap input[type="checkbox"]:checked:disabled + label:hover {background:url('../../images/img/checkbox_checked_disabled.png') no-repeat;cursor: default;}

.checkboxWrap label.table {
    text-indent: -9999px;
}
.checkboxWrap input[type="checkbox"] {
    width: 0;
    height: 0;
    margin: 0;
    display: none;
    position: absolute;
}

.ReactTable .checkboxWrap input[type="checkbox"] + label {
    display:inline-block;
    background: url('../../images/img/sign_checkbox_normal.png') no-repeat;
    color: #70767e;

}
.ReactTable .checkboxWrap input[type="checkbox"]:checked + label {background:url('../../images/img/checkbox_checked.png') no-repeat;}

