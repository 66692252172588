.storage-summary {
    width: 100%;
    height: 100%;
    margin-top: 12px;
    position: relative;
}

.storage-summary .right {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../images/icon/widget_ic_right.png) no-repeat 10px 0;
}

.storage-summary .right:hover {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../images/icon/widget_ic_right_dim.png) no-repeat 10px 0;
}

.storage-summary .left {
    isplay: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 33px;
    height: 33px;
    border: 0;
    cursor: pointer;
    background: url(../../images/icon/widget_ic_left.png) no-repeat 0 0;
}

.storage-summary .left:hover {
    background: url(../../images/icon/widget_ic_left_dim.png) no-repeat 0 0;
}

.storage-summary .server_detail {
    float: left;
    margin-right: 10px;
    padding: 5px 0 0 5px;
    width: 120px;
    position: absolute;
    left: 30px;
}

.storage-summary .li {
    margin: 3px 3px 3px 0;
    padding: 1px;
    float: left;
    height: auto;
    width: 100%;
}

.storage-summary .server_detail .storage_title{
    color: #363636;
    font-size: 10px;
    display: block;
}

.storage-summary .server_detail .storage_total{
    color: #363636;
    font-size: 16px;
    display: block;
    height: 21px;
}

.storage-summary .server_detail .storage_used{
    color: #6e78c8;
    font-size: 16px;
    display: block;
    height: 21px;
}

.storage-summary .server_detail .storage_available{
    color: #b7bbd9;
    font-size: 16px;
    display: block;
    height: 21px;
}

.storage-summary .chart {
    width: 280px;
    height: 100%;
    position: absolute;
    left: 100px;
}

.storage-summary .chart_info {
    position: absolute;
    top: 50px;
    right: 72px;
    text-align: center;
    width: 136px;
    height: 45px;
}

.storage-summary .chart_info .root-path {
    font-size: 10px;
    color: #6e78c8;
}

.storage-summary .chart_info .storage_used {
    font-size: 20px;
    color: #6e78c8;
}
