.dashboard {
    overflow-y: auto;
    width: 100%;
    position: relative;
    margin-bottom: 60px;
    margin-top: 5px;
    min-width: 920px;
}

.dashboard-panel {
    margin: 0px 30px 30px 12px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.dashboard-widget {
    width: 410px;
    height: 264px;
    display: inline-flex;
    flex-direction: column;
    background-color: #fafafc;
    margin-bottom: 20px;
    margin-left: 18px;
    border-radius: 3px;
    border: 1px solid #edf1f4;
}

.dashboard-widget-drag-mode {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    pointer-events: auto !important;
    cursor: grabbing !important;
}

.dashboard-widget-header {
    position: relative;
    width: 100%;
    height: 42px;
    background-color: #f3f4f5;
    display: flex;
}

.dashboard-widget-header .title {
    color: #363636;
    font-weight: bold;
    font-size: 13px;
    line-height: 44px;
    padding-left: 16px;
    width: 100%;
    float: left;
}

.dashboard-widget-header .edit {
    position: absolute;
    width: 27px;
    height: 27px;
    transform: translate(0%,-50%);
    top: 50%;
    right: 5px;
    background: url(../../images/icon/lnb_icon3.png) no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
}

.dashboard-widget-header .edit:hover {
    cursor: pointer;
    background: url(../../images/icon/lnb_icon3.png) no-repeat;
    background-position-x: 0%;
    background-position-y: 100%;
}


.dashboard-widget-header .remove {
    background: url(../../images/icon/dashboard_delete_normal.png) no-repeat;
    width: 19px;
    height: 19px;
    float: right;
    margin: 12px 5px;
    display: block;
}

.dashboard-widget-header .remove:hover {
    cursor: pointer;
}

.dashboard-widget-body {
    height: 100%;
    margin: 15px;
    position: relative;
}

/* width */
.dashboard-widget-body::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.dashboard-widget-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.dashboard-widget-body::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 5px;
}

/* Handle on hover */
.dashboard-widget-body::-webkit-scrollbar-thumb:hover {
    background: #999;
}
