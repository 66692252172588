.device_edit_panel_wrap .whitelist_question {
    border: 0px;
    background: url('../../../../images/button/tutorial_normal.png') no-repeat;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    margin-left: 10px;
    display: inline-block;
}

.device_edit_panel_wrap .whitelist_question:hover {
    background: url('../../../../images/button/tutorial_hover.png') no-repeat;
}

.device_edit_panel_wrap .white_list_edit {
    width: 22px;
    height: 22px;
    background: url('../../../../images/icon/icon_edit_nor.png') no-repeat;
    float: right;
    top: 3px;
    margin-left: 10px;
    right: 60px;
    border: 0;
    position: relative;
    cursor: pointer;
}

.device_edit_panel_wrap .white_list_edit:hover {
    background: url('../../../../images/icon/icon_edit_over.png') no-repeat;
}

.device_edit_panel_wrap .white_list_delete {
    width: 22px;
    height: 22px;
    background: url('../../../../images/icon/icon_del_nor.png') no-repeat;
    float: right;
    top: 3px;
    margin-left: 10px;
    right: 0px;
    border: 0;
    position: relative;
    cursor: pointer;
}

.device_edit_panel_wrap .white_list_delete:hover {
    background: url('../../../../images/icon/icon_del_over.png') no-repeat;
}

.device_edit_panel_wrap .whitelist_guide {
    max-width: 720px; 
    white-space: normal;
    position: absolute;
    left: 190px;
    bottom: 0px;
    background: #fff;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 1px 1px 20px #888;
}