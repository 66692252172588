.realtime-connected-device {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
}

.realtime-connected-device-chart {
    width: 100%;
    height: 174px;
}

.realtime-connected-device-chart > canvas {
    z-index: 100;
    position:relative;
}

.realtime-connected-device-guide {
    width: 100%;
    height: 16px;
    position: relative;
    color: rgb(84, 84, 84);
}

.realtime-connected-device-guide-ago {
    position: absolute;
    left: 25px;
    font-size: smaller;
}

.realtime-connected-device-guide-current {
    position: absolute;
    right: 0;
    font-size: smaller;
}