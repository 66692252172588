.content-summary {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: row;
}

.content-summary-all {
    width: 50%;
    height: 125px;
    background: rgba(132,208,212,0.3);
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
    color: #363636;
    font-size: 34px;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}

.content-summary-all > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.content-summary-running {
    width: 50%;
    height: 125px;
    font-size: 34px;
    background: rgba(132,208,212,0.6);
    color: #363636;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}

.content-summary-running > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.content-summary-unapproved {
    width: 50%;
    height: 125px;
    background: #84D0D4;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    font-size: 34px;
    line-height: 66px;
    text-align: center;
    padding-top: 10px;
}


.content-summary-unapproved > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}