body {
    overflow-y: hidden;
    overflow-x: hidden;
}

.dashboardWrap {
    display: inline-flex;
    flex-flow: column;
    position: relative;
    height: calc(100vh - 75px);
    width: 100%;
}

.dashboardWrap .btnWrap {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 17px;
    display: inline-flex;
}

.sDashboard {
    list-style-type: none;
    margin-top: 20px;
    margin-left: 30px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}

.sDashboard li {
    margin: 3px 3px 3px 0;
    padding: 1px;
    float: left;
    width: 400px;
    height: auto;
}

.sDashboardWidget {
    width: 400px;
    margin: 0 1em 1em 0;
    border: 1px solid #CBCBCB;
    background-color: #fafafc;
    border-radius: 3px;
    border: 1px solid #edf1f4;
    -webkit-transition: border-color 0.3s;
    -moz-transition: border-color 0.3s;
    transition: border-color 0.3s;
}

.sDashboardWidgetHeader {
    padding-left: 15px;
    padding-top: 10px;
    border: 0;
    background-color: #f3f4f5;
    color: #363636;
    font-weight: bold;
    font-size: 13px;
}

.sDashboardWidgetContent {
    margin: 15px;
    margin-top: 15px;
    height: 180px;
    overflow: hidden;
    width: 357px;
    margin-top: 15px;
}

.sDashboardWidgetContent .user_thum {
    padding: 0;
    float: left;
}

.sDashboardWidgetContent .user_thum > span {
    display: block;
    font-size: 10px;
    color: #363636;
    padding-top: 12px;
}

.sDashboardWidgetContent .user_detail {
    float: left;
    padding: 0px;
}

.sDashboardWidgetContent .user_detail ul > li {
    width: 200px;
    padding: 0px;
}

.sDashboard-clearfix::after {
    clear: both;
}

.sDashboard-clearfix::before, .sDashboard-clearfix::after {
    content: "";
    display: table;

}

.sDashboard-edit {
    width: 100%;
    padding: 12px;
    background: #e1e5e8;
    margin-bottom: 20px;
    display:inline-block;
}

.sDashboard-edit-menu {
    float: left;
    line-height: 52px;
    width: 365px;
    margin-right: 18px;
    margin-top: 10px;
}

.sDashboard-edit-menu > h4 {
    font-size: 13px;
    font-weight: bold;
    line-height: 52px;
    background: #FFF none repeat scroll 0% 0%;
    padding: 0 12px 0 16px;
    color: #363636;
    margin: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.sDashboard-edit-menu .add {
    float: right;
    width: 19px;
    height: 19px;
    background: url(../../images/icon/widget_ic_noti_add.png);
    margin-top: 17px;
    border: 0;
    outline: 0;
}

.sDashboard-edit-menu .add:hover {
    cursor: pointer;
}

.copyRight {
    height: 50px;
    width: 100%;
    background: #e6eaed;
    position: absolute;
    bottom: 0;
    text-align: center;
    line-height: 18px;
    padding-top: 10px;
    z-index: 100;
}

.copyRight > a {
    text-decoration: none;
    color: #609be8;
    cursor: pointer;
}

.dashboard_refresh_btn {
    display: inline-block;
    height: 28px;
    width: 28px;
    background: url(../../images/icon/refresh_normal.png) no-repeat 5px 6px;
}
