.sub_tab_wrap {
    width: 100%;
    height: 38px;
    position: relative;
    overflow: hidden;
    background: url('../../images/bg/datetab_bg2.png') repeat-x bottom;
}
.sub_tab_wrap.h42 {
    height: 42px;
}
.sub_tab_wrap > ul {
    position: absolute;
    top: 0;
    left: 0px;
}
.sub_tab_wrap > ul.position_error {
    left: -40px;
}
.sub_tab_wrap > ul > li > .err {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url('../../images/icon/icon_tab_error2.png') no-repeat;
}
.sub_tab_wrap > ul > li > .normal {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url('../../images/icon/icon_tab_normal2.png') no-repeat;
}
.sub_tab_wrap > ul > li {
    float: left;
    line-height: 17px;
    text-align: center;
    cursor: pointer;
}
.sub_tab_wrap > ul > li:last-child {
    margin-right: 0px;
}
.sub_tab_wrap > ul > li.on > span {
    color: #3196ff;
    background: url('../../images/bg/datetab_bg_selected.png') no-repeat center bottom
        1px;
    background-size: 80% 2px;
} /*set 1px to prevent IE bug*/
.sub_tab_wrap > ul > li > span {
    display: inline-block;
    width: auto;
    max-width: 120px;
    color: #ababac;
    font-size: 13px;
    color: #686b72;
    height: 28px;
    letter-spacing: -1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sub_tab_wrap.h42 > ul > li > span {
    height: 32px;
}
.sub_tab_wrap > ul > li:hover > span {
    color: #000; /*background:url('..../images/bg/datetab_bg.png') no-repeat center bottom 1px;*/
    background-color: #d0d3d8;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}
.sub_tab_wrap > ul > li:active > span {
    color: #000; /*background:url('..../images/bg/datetab_bg.png') no-repeat center bottom 1px;*/
}
.sub_tab_wrap > ul > li.dim > span {
    color: #c3c4c6;
}
.sub_tab_wrap > ul > li.dim:hover > span {
    background: none;
}

.sub_tab_wrap .tabtitle {
    display: inline;
    float: left;
    list-style: none;
    margin: 0;
    padding: 1px 1px;
    text-align: center;
}
.sub_tab_wrap .tabtitle.next > button {
    width: 27px;
    height: 27px;
    line-height: 27px;
    border: 0;
    margin-top: 8px;
    background: url('../../images/button/date_select_pop_next.png') no-repeat 6px 4px;
}
.sub_tab_wrap .tabtitle.next > button:hover {
    background: url('../../images/button/date_select_pop_next_off.png') no-repeat 6px
        4px;
}
.sub_tab_wrap .tabtitle.prev > button {
    width: 27px;
    height: 27px;
    line-height: 27px;
    border: 0;
    margin-top: 8px;
    background: url('../../images/button/date_select_pop_prev.png') no-repeat 6px 4px;
}
.sub_tab_wrap .tabtitle.prev > button:hover {
    background: url('../../images/button/date_select_pop_prev_off.png') no-repeat 6px
        4px;
}
