/* for react table */
.ReactTable
{
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    border:1px solid rgba(0,0,0,0.1)
}
.ReactTable *
{
    box-sizing:border-box;
}
.ReactTable .rt-table
{
    -webkit-box-flex:1;
    -ms-flex:auto 1;
    flex:auto 1;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;
    width:100%;
    border-collapse:collapse;
    overflow:auto
}
.ReactTable .rt-thead
{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.ReactTable .rt-thead.-headerGroups
{
    background:rgba(0,0,0,0.03);
    border-bottom:1px solid rgba(0,0,0,0.05)
}
.ReactTable .rt-thead.-filters
{
    border-bottom:1px solid rgba(0,0,0,0.05)
}
.ReactTable .rt-thead.-filters input,.ReactTable .rt-thead.-filters select
{
    border:1px solid rgba(0,0,0,0.1);
    background:#fff;
    padding:5px 7px;
    font-size:inherit;
    border-radius:3px;
    font-weight:normal;
    outline-width:0
}
.ReactTable .rt-thead.-filters .rt-th
{
    border-right:1px solid rgba(0,0,0,0.02)
}
.ReactTable .rt-thead.-header
{
    box-shadow:0 2px 15px 0 rgba(0,0,0,0.15)
}
.ReactTable .rt-thead .rt-tr
{
    text-align:left;
    border: 0;
    border-bottom: 1px solid #d2d2d3;
    background: #eeeeef;
    position: relative;
}
.ReactTable .rt-thead .rt-th,.ReactTable .rt-thead .rt-td
{
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    background: url('../../images/bg/table_divider.png') no-repeat right;
    position: relative;
    transition:box-shadow .3s cubic-bezier(.175,.885,.32,1.275);
    box-shadow:inset 0 0 0 0 transparent
}
.ReactTable .rt-thead .rt-th.-sort-asc,.ReactTable .rt-thead .rt-td.-sort-asc
{
    box-shadow:inset 0 3px 0 0 rgba(0,0,0,0.6)
}
.ReactTable .rt-thead .rt-th.-sort-desc,.ReactTable .rt-thead .rt-td.-sort-desc
{
    box-shadow:inset 0 -3px 0 0 rgba(0,0,0,0.6)
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,.ReactTable .rt-thead .rt-td.-cursor-pointer
{
    cursor:pointer
}
.ReactTable .rt-thead .rt-th:last-child,.ReactTable .rt-thead .rt-td:last-child
{
    border-right:0
}
.ReactTable .rt-thead .rt-th:focus
{
    outline-width:0
}
.ReactTable .rt-thead .rt-resizable-header
{
    overflow:visible
}
.ReactTable .rt-thead .rt-resizable-header:last-child
{
    overflow:hidden
}
.ReactTable .rt-thead .rt-resizable-header-content
{
    overflow:hidden;
    text-overflow:ellipsis
}
.ReactTable .rt-thead .rt-header-pivot
{
    border-right-color:#f7f7f7
}
.ReactTable .rt-thead .rt-header-pivot:after,.ReactTable .rt-thead .rt-header-pivot:before
{
    left:100%;
    top:50%;
    border:solid transparent;
    content:" ";
    height:0;
    width:0;
    position:absolute;
    pointer-events:none
}
.ReactTable .rt-thead .rt-header-pivot:after
{
    border-color:rgba(255,255,255,0);
    border-left-color:#fff;
    border-width:8px;
    margin-top:-8px
}
.ReactTable .rt-thead .rt-header-pivot:before
{
    border-color:rgba(102,102,102,0);
    border-left-color:#f7f7f7;
    border-width:10px;
    margin-top:-10px
}
.ReactTable .rt-tbody
{
    -webkit-box-flex:99999;
    -ms-flex:99999 1 auto;
    flex:99999 1 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    overflow:auto;
}

.ReactTable .rt-tbody .rt-tr-group:hover {
    background:#eceef3;
    border-bottom: 1px solid #d2d2d3;
}

.ReactTable .rt-tbody .rt-tr-group.on:hover {
    background:#eceef3;
    border-bottom: 1px solid #eceef3;
}

.ReactTable .rt-tbody .rt-tr-group:hover input[type="checkbox"] + label {
    background: url(../../images/img/checkbox_normal.png) no-repeat;
}

.ReactTable .rt-tbody .rt-tr-group:hover input[type="checkbox"]:checked + label {
    background: url(../../images/img/checkbox_checked.png) no-repeat;
}

.ReactTable .rt-tbody .rt-tr-group.selected {
    background: #e6f2ff;
    min-width: max-content;
}

.ReactTable .rt-tbody .rt-tr-group
{
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 7px 10px 7px 0px;
    line-height: 20px;
    position: relative;
    min-width: max-content;
}
.ReactTable .rt-tbody .rt-tr-group:last-child
{
    border-bottom:0
}
.ReactTable .rt-tbody .rt-td
{
    border-right:1px solid rgba(0,0,0,0.02);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ReactTable .rt-tbody .rt-td:last-child
{
    border-right:0
}
.ReactTable .rt-tbody .rt-expandable
{
    cursor:pointer;
    text-overflow:clip
}
.ReactTable .rt-tr-group
{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    /*flex:1 0 auto;*/
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;


}
.ReactTable .rt-tr
{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
}
.ReactTable .rt-th,.ReactTable .rt-td
{
    -webkit-box-flex:1;
    -ms-flex:1 0 0px;
    flex:1 0 0;
    white-space:nowrap;
    text-overflow:ellipsis;
    padding:7px 5px;
    overflow:hidden;
    transition:.3s ease;
    transition-property:width,min-width,padding,opacity



}
.ReactTable .rt-th.-hidden,.ReactTable .rt-td.-hidden
{
    width:0 !important;
    min-width:0 !important;
    padding:0 !important;
    border:0 !important;
    opacity:0 !important
}
.ReactTable .rt-expander
{
    display:inline-block;
    position:relative;
    margin:0;
    color:transparent;
    margin:0 10px
}

.ReactTable .rt-expander {
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/icon/calander_icon_hover.png') no-repeat 0 0;
    background: url('../../images/button/plus_icon_press.png') no-repeat 0 0;
}

.ReactTable .rt-expander.-open {
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/icon/calander_icon_hover.png') no-repeat 0 0;
    background: url('../../images/button/minus_icon_press.png') no-repeat 0 0;
}

.ReactTable .rt-expander:hover
{
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/button/plus_icon_over.png') no-repeat 0 0;
}

.ReactTable .rt-expander.-open:hover
{
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/button/minus_icon_over.png') no-repeat 0 0;
}
.ReactTable .rt-expander.-open:after
{
    -webkit-transform:translate(-50%,-50%) rotate(0);
    transform:translate(-50%,-50%) rotate(0)
}
.ReactTable .rt-resizer
{
    display:inline-block;
    position:absolute;
    width:36px;
    top:0;
    bottom:0;
    right:-18px;
    cursor:col-resize;
    z-index:10
}
.ReactTable .rt-tfoot
{
    -webkit-box-flex:1;
    -ms-flex:1 0 auto;
    flex:1 0 auto;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    box-shadow:0 0 15px 0 rgba(0,0,0,0.15)
}
.ReactTable .rt-tfoot .rt-td
{
    border-right:1px solid rgba(0,0,0,0.05)
}
.ReactTable .rt-tfoot .rt-td:last-child
{
    border-right:0
}
.ReactTable.-striped .rt-tr.-odd
{

}

.ReactTable .-pagination
{
    z-index:1;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    padding:3px;
    box-shadow:0 0 15px 0 rgba(0,0,0,0.1);
    border-top:2px solid rgba(0,0,0,0.1)
}
.ReactTable .-pagination input,.ReactTable .-pagination select
{
    border:1px solid rgba(0,0,0,0.1);
    background:#fff;
    padding:5px 7px;
    font-size:inherit;
    border-radius:3px;
    font-weight:normal;
    outline-width:0
}
.ReactTable .-pagination .-btn
{
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    display:block;
    width:100%;
    height:100%;
    border:0;
    border-radius:3px;
    padding:6px;
    font-size:1em;
    color:rgba(0,0,0,0.6);
    background:rgba(0,0,0,0.1);
    transition:all .1s ease;
    cursor:pointer;
    outline-width:0
}
.ReactTable .-pagination .-btn[disabled]
{
    opacity:.5;
    cursor:default
}

.ReactTable .-pagination .-previous,.ReactTable .-pagination .-next
{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    text-align:center
}
.ReactTable .-pagination .-center
{
    -webkit-box-flex:1.5;
    -ms-flex:1.5;
    flex:1.5;
    text-align:center;
    margin-bottom:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
    -ms-flex-direction:row;
    flex-direction:row;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-pack:distribute;
    justify-content:space-around
}
.ReactTable .-pagination .-pageInfo
{
    display:inline-block;
    margin:3px 10px;
    white-space:nowrap
}
.ReactTable .-pagination .-pageJump
{
    display:inline-block
}
.ReactTable .-pagination .-pageJump input
{
    width:70px;
    text-align:center
}
.ReactTable .-pagination .-pageSizeOptions
{
    margin:3px 10px
}
.ReactTable .rt-noData
{
    display:block;
    position:absolute;
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%);
    background:rgba(255,255,255,0.8);
    transition:all .3s ease;
    z-index:1;
    pointer-events:none;
    padding:20px;
    color:rgba(0,0,0,0.5)
}
.ReactTable .-loading
{
    display:block;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background:rgba(255,255,255,0.8);
    transition:all .3s ease;
    z-index:-1;
    opacity:0;
    pointer-events:none
}
.ReactTable .-loading > div
{
    position:absolute;
    display:block;
    text-align:center;
    width:100%;
    top:50%;
    left:0;
    font-size:15px;
    color:rgba(0,0,0,0.6);
    -webkit-transform:translateY(-52%);
    transform:translateY(-52%);
    transition:all .3s cubic-bezier(.25,.46,.45,.94)
}
.ReactTable .-loading.-active
{
    opacity:1;
    z-index:2;
    pointer-events:all
}
.ReactTable .-loading.-active > div
{
    -webkit-transform:translateY(50%);
    transform:translateY(50%)
}
.ReactTable .rt-resizing .rt-th,.ReactTable .rt-resizing .rt-td
{
    transition:none !important;
    cursor:col-resize;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}

/* for table */

.contents_buttonWrap {
    height: 44px;
    position: relative;
    display: inline-block;
    width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contents_buttonWrap button:first-child {
    margin-left: 0
}

.contents_buttonWrap button {
    margin-left: 10px;
}


.contents_buttonWrap > .leftButton {
    display: inline-block;
    margin-left: 20px;
    float:left;
}

.contents_buttonWrap > .rightButton {
    display: inline-block;
    margin-right: 30px;
    float:right;
}

.overflow_hidden {
    /*overflow: hidden;*/
    /*margin-bottom: 0;*/
    /*height: 56px;*/
}

.ReactTable span.data_name {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}
.ReactTable span.data_name:hover{
    text-decoration: underline;
}

/* for image view */
.image_view_wrap {
    word-break: break-all;
}

.image_view_wrap > div > div table > thead > tr > th {vertical-align:middle;padding:7px 10px 7px 10px;background:url('../../images/bg/table_divider.png') no-repeat right;position:relative;}
.image_view_wrap > div > div table > thead > tr > th:first-child {background:none;padding:7px 0 7px 17px;}
.image_view_wrap > div > div table > thead > tr > th.bar {background:url('../../images/bg/table_divider.png') no-repeat right}
.image_view_wrap > div > div table > thead > tr > th.bgnone {background:none;}
.image_view_wrap > div > div table > thead > tr > th:last-child {border-right:0;background:none}
.image_view_wrap > div > div table > thead > tr > th.icon:hover {background:#f3f3f3;border-top:1px solid #e0e0e0;border-bottom:1px solid #e0e0e0;}
.image_view_wrap > div > div table > thead > tr > th.icon:active {background:#f0f0f0;}
.image_view_wrap > div > div table > thead > tr > th.icon > a {display:none}
.image_view_wrap > div > div table > thead > tr > th.icon:hover > a {display:block;width:15px;height:15px;float:right;margin-right:0px;padding:0;background:url('../../images/img/list_arrow.png') no-repeat 5px 7px;}
.image_view_wrap > div > div table > thead > tr > th:last-child.icon:hover  > a {margin-right:10px;}

.image_view_wrap table thead {
    border: 0;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    background: #eeeeef;
    position: relative;
}
.image_view_wrap table {
    width: 100%;
    position: relative;
}
.image_view_wrap .sorting_wrap {
    position: absolute;
    right: 0;
    top: 0;
}
.image_view_wrap .size_control_wrap {
    position: relative;
    border-left: 1px solid
    #f0f0f0;
}
.image_view_wrap .list {
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 0;
    margin-right: 0;
    min-width: 500px;
}

.image_view_wrap .thumbnailview_wrap ul {

}

.image_view_wrap .thumbnailview_wrap li {
    float: left;
    margin: 8px 8px 0 0;
}
.image_view_wrap .thumbnailview_wrap .thumbview_box {
    width: 100px;
    height: 100px;
    position: relative;
}
.image_view_wrap .device_info, .device_tag_info {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
    color: #000;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    z-index: 100;
}
.image_view_wrap .device_info.add {
    font-size: 8px;
}
.image_view_wrap .device_info.add > div {
    height: 100%;
    text-align: left;
    vertical-align: middle;
    padding: 15px 0 0 15px;
}
.image_view_wrap .device_info > div {
    display: table;
    width: 100%;
    height: 100%;
}
.image_view_wrap .device_info:hover {display:block;}
.image_view_wrap .device_info strong {display:block;padding:0 0 0 0;}
.image_view_wrap .device_info span {color:#7f7f7f;}

.image_view_wrap .thumbnailview_wrap .name {
    font-size: 10px;
    text-decoration: none;
    color: #818181;
    text-align: center;
}
.image_view_wrap .thumbview_box:hover .device_info {display:table;}
.image_view_wrap .thumbview_box.tiny:hover .device_info {display:none;}
.image_view_wrap .thumbview_box.information .device_info {display:table;}
.image_view_wrap .thumbview_box.information.tiny .device_info {display:none;}
.image_view_wrap .thumbview_box.tag_information .device_tag_info {display:block;}
.image_view_wrap .thumbview_box.tag_information.tiny .device_tag_info {display:none;}

.sorting.select.bl {
    border-left: 1px solid
    #f0f0f0;
}
.image_view_wrap .sorting:hover {
    background: url('../../images/button/select_down.png') no-repeat right;
    background-position-x: right;
    background-position-y: center;
    background-position: center right;
}
.image_view_wrap .sorting.select {
    padding-left: 13px;
    padding-right: 6px;
    text-align: left;
    background: none;
    border: 0;
    border-right-color: currentcolor;
    border-right-style: none;
    border-right-width: 0px;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: 0px;
    height: 33px;
    line-height: 30px;
    color: #797979;
    border-right: 1px solid #f0f0f0;
}
.image_view_wrap .select_box .option_list.sorting_list {
    top: 34px;
    left: 0;
}
.image_view_wrap .option_list {
    width: 100%;
    position: absolute;
    top: 37px;
    left: 0;
    background: #fff;
    border: 1px solid #dde0e5;
    box-shadow: #dedede 0px 1px 0px;
    z-index: 1;
}
.image_view_wrap .sorting.select.asc span.desc {
    display: none;
}
.image_view_wrap .sorting.select span {
    width: 100%;
}
.image_view_wrap .thumbnailview_wrap .check {display:none;position:absolute;top:0;left:0;width:100%;height:100%;background:url('../../images/bg/thumb_layer_bg.png') repeat;}
.image_view_wrap .thumbnailview_wrap .check span {width:100%;height:100%;display:inline-block;background:url('../../images/img/thumb_check.png');background-repeat: no-repeat;background-position: center center;background-size:60px;}
.image_view_wrap .thumbview_box.on .check {display:block}


.ReactTable .schModifyBtn {
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/button/plus_icon_press.png') no-repeat 0 0;
}

.schModifyBtn:hover {
    background: url('../../images/button/plus_icon_over.png') no-repeat 0 0;
}

.ReactTable .schModifyBtn.on {
    height: 22px;
    width: 32px;
    border: 0px;
    float: right;
    background: url('../../images/button/minus_icon_press.png') no-repeat 0 0;
}

.content_schedule_table_warp .ReactTable .rt-thead.-header .rt-tr > .rt-th:nth-child(2) {
    background: none;
}


.content_schedule_table_warp .ReactTable .rt-tbody .rt-tr-group {
    padding: 7px 0px 0px 0px;
}

.tag_setting_body .ReactTable .rt-tbody .rt-td {
    height: 40px;
}

.tag_setting_body.height_small .ReactTable .rt-tbody .rt-td {
    height: 34px;
}

.ruleset_table_wrap .ReactTable .rt-tbody .rt-tr-group {
    padding: 7px 0px 0px 0px;
}

/*
.content_schedule_list_view_wrap .content_schedule_tr_group {
    max-height: 80px;
}*/


.content_schedule_table_warp .status_wrap {
    display: block;
    height: 4px;
    width: 100%;
    background: #edf2f7;
    padding: 0;
    position: relative;
}
.content_schedule_table_warp .status_wrap > span {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
}

.device_list_view_wrap .ReactTable .rt-table .rt-thead.-header .rt-tr {
    width: calc(100% + 0px);
}

.device_list_view_wrap .ReactTable .rt-table .rt-tbody {
    min-width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    min-width: fill-available !important;
    min-width: -moz-available !important;         /* WebKit-based browsers will ignore this. */
    width: max-content;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
    background: url('../../images/button/select_up.png') no-repeat right !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
    background: url('../../images/button/select_down.png') no-repeat right !important;
}

.magicinfo_table_wrapper .rt-table {overflow-y:auto; overflow-x:hidden;}
.magicinfo_table_wrapper .rt-tbody {max-width:100%;}

.magicinfo_table_wrapper .ReactTable .rt-table .rt-tbody {
    min-width: -webkit-fill-available !important;  /* Mozilla-based browsers will ignore this. */
    min-width: fill-available !important;
    min-width: -moz-available !important;         /* WebKit-based browsers will ignore this. */
    width: max-content;
}


.ReactTable .list_more_wrap {top:25px;right:20px;border:0;width:21px;height:21px;background:url('../../images/icon/list_more_normal.png') no-repeat;}
.ReactTable .list_more_wrap:hover {background:url('../../images/icon/list_more_hover.png') no-repeat;}
.ReactTable .list_more_wrap.on,
.ReactTable .list_more_wrap:active {background:url('../../images/icon/list_more_select.png') no-repeat;}

.ReactTable .list_more {width:105px;position:absolute;background:#fff;border:1px solid #dde0e5;box-shadow:#dedede 0px 1px 0px;z-index:10}
.ReactTable .list_more > div {position:relative;}
.ReactTable .list_more > div > span {display:block;width:100%;height:8px;position:absolute;top:-8px;background:url('../../images/bg/option_list_bg.png') no-repeat 10px 0;}
.ReactTable .list_more > div > ul {height:166px;overflow-x:hidden;overflow-y:scroll;}
.ReactTable .list_more > div > ul > li {padding:6px 5px 5px 13px;font-size:12px;position:relative;line-height:15px;text-align:left}
.ReactTable .list_more.checklist > div > ul > li {padding:6px 5px 5px 8px;}
.ReactTable .list_more.ta_c > div > ul > li {padding:6px 5px 5px 5px;text-align: center}
.ReactTable .list_more > div > ul > li:first-child {padding-top:11px}
.ReactTable .list_more > div > ul > li:last-child {border-bottom:0;padding-bottom:10px}
.ReactTable .list_more > div > ul > li > a {display:inline-block;color:#686b72;text-decoration: none;width:100%;font-size:11px;vertical-align:middle;}
.ReactTable .list_more > div > ul > li > a img {vertical-align:middle;margin-right:5px;}
.ReactTable .list_more > div > ul > li > a:hover {color:#609be8 !important;}
.ReactTable .list_more > div > ul > li > a:active {color:#2789f4 !important;}
.ReactTable .list_more > div > ::-webkit-scrollbar {width:16px;}

.ReactTable .rt-tbody .rt-td.not-expandable {visibility: hidden}