.group_list {padding:0 20px;width:100%;}
.group_list > div {width:35%;border-radius:3px;position:relative;}
.group_list > div > span {width:100%;font-weight:bold;line-height:52px;padding-left:14px;}
.group_list > div > ul {clear:both;height:182px;overflow-y:scroll;border: 1px solid #e5e5e5;margin-top:12px;border-right:0;}
.group_list > div > ul > li {border-bottom:1px solid #e5e5e5;line-height:30px;padding-left:15px}
.group_list > div > ul > li:last-child {border-bottom:0 solid #e5e5e5;}
/* .group_list > div > ::-webkit-scrollbar {width:16px;}  */


.space {line-height:20px}
.space.explain {color:#a9a9a9;font-size:11px;}
.space2 {line-height:27px;background:url('../../images/bg/bar_5.png') no-repeat 0 8px;padding-left:15px;}

.selectedDeviceDelBtn  {height: 19px;width: 19px;background: url('../../images/img/tab_close_normal.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}
.selectedDeviceDelBtn:hover {height: 19px;width: 19px;background: url('../../images/img/tab_close_hover.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}
.selectedDeviceDelBtn:active {height: 19px;width: 19px;background: url('../../images/img/tab_close_select.png') no-repeat 0 0;border: 0;padding: 0; margin: 0;}

.connect_history_view {
    height: calc(100% - 391px);
}
.connect_history_view .connect_history_tr_body {
    overflow-y: overlay;
}
.connect_history_view .connect_history_tr_group {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding: 0px !important;
}
.connect_history_view .connect_history_td_props {
    padding: 10px 10px !important;
}