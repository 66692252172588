.device-summary {
    width: 100%;
    height: 71px;
    display: inline-flex;
    flex-direction: row;
    margin: 5px 0px 15px 0px;
}

.device-summary-connected {
    width: 33%;
    height: 71px;
    background: #D0E1F6;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
    color: #363636;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
}

.device-summary-connected > span {
    font-size: 10px;
    display: block;
    line-height: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.device-summary-disconnected {
    width: 34%;
    height: 71px;
    background: #B5D0F0;
    color: #363636;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
}

.device-summary-disconnected > span {
    font-size: 10px;
    display: block;
    line-height: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.device-summary-unapproved {
    width: 33%;
    height: 71px;
    background: #70a7e6;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    color: #ffffff;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
}

.device-summary-unapproved > span {
    font-size: 10px;
    display: block;
    line-height: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.device-health {
}

.device-health-info {
    margin-left: 10px;
    margin-right: 10px;
    display: block;
}

.device-health-info > ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.device-health-info > ul > li {
    width: 100%;
    height: 28px;
    margin: 3px 3px 3px 0;
    cursor: pointer;
}

.device-health-info .icon {
    float: left;
}

.device-health-info .title {
    float: left;
    font-size: 12px;
    color: #363636;
}

.device-health-info .count {
    display: inline;
    float: right;
    font-size: 20px;
}