#typeDetails .curr_time .plus_btn {
    float: left;
    margin-top: 2px;
    width: 27px;
    height: 27px;
    border: 0px;
    background: url("./images/folder_plus.png") no-repeat 2px 1px;
    position: static;
}

li.time_range .delete {
    border: 0;
    width: 19px;
    height: 19px;
    background: url("./images/dashboard_delete_normal.png") no-repeat;
    vertical-align: middle;
}

li.time_range .delete:hover {
    background: url("./images/dashboard_delete_hover.png");
}

.ruleset_condition_detail_view {
    position: relative;
}
.ruleset_condition_detail_view table #rulesetConditionTypeDiv {
    max-height: 400px;
    overflow-y: auto;
}
.ruleset_condition_detail_view table #rulesetConditionTypeDiv th{
   width : 150px;   
}

#rulesetConditionTypeDiv .curr_time .time_range{
    max-height: 200px;
    overflow-y:auto;
}

.ruleset_condition_detail_view.t_line {
    border-top: 1px solid #f4f4f4;
}
.ruleset_condition_detail_view table {
    width: 100%;
}
.ruleset_condition_detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-left: 10px;
}
.ruleset_condition_detail_view.user_info table th {
    font-size: 13px;
}
.ruleset_condition_detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}
.ruleset_condition_detail_view table tr.bullet_line td {
    padding: 2px 0 1px 0;
    color: #474747;
}
.ruleset_condition_detail_view table tr.va_t th {
    vertical-align: top;
    padding-top: 11px;
}
.ruleset_condition_detail_view table td label {
    color: #474747;
    display: inline-block;
}
.ruleset_condition_detail_view table td.icon img {
    vertical-align: middle;
    margin-right: 4px;
}
.ruleset_detail_view table td textarea {
}
.ruleset_condition_detail_view table th img {
    margin-left: 6px;
    vertical-align: middle;
}

.ruleset_condition_detail_view table th h4 {
    margin: 0;
    font-size: 16px;
    color: #676767;
    font-weight: normal;
    margin-bottom: 10px;
}
.ruleset_condition_detail_view .space {
    display: block;
}
.ruleset_condition_detail_view .speed_controler {
    margin-top: 0;
}
.ruleset_condition_detail_view .speed_controler > span {
    width: 273px;
}
.ruleset_condition_detail_view .speed_controler > span > .txt {
    font-size: 10px;
    top: 20px;
    background: none;
    color: #b3b4b5;
}
.ruleset_condition_detail_view.tline {
    border-top: 1px solid #f5f5f5;
}
.ruleset_condition_detail_view table td .txt {
    color: #8d8d8d;
    font-size: 12px;
    display: block;
    line-height: 17px;
}

.ruleset_setting_condition {
    width: 100%;
}

.holiday_add {
    width: 19px;
    height: 19px;
    background: url("./images/dashboard_add_normal.png");
    margin-left: 10px;
    border: 0;
    position: relative;
    top: 5px;
}
.holiday_add:hover {
    background: url("./images/dashboard_add_hover.png");
}
.holiday_add:active {
    background: url("./images/dashboard_add_select.png");
}

.replash2 {
    display: block;
    border: 0;
    width: 25px;
    height: 25px;
    background: url("./images/reflash_normal2.png");
}

.ruleset_tag_input {
    position: relative;
    display: inline-block;
    padding: 0 22px 0 7px !important;
    background: #eff2f6;
    border: 1px solid #d6dee8;
    border-radius: 15px;
    line-height: 26px;
    margin-bottom: 1px;
}
.ruleset_tag_input > input[type="text"] {
    line-height: 28px;
    background: #eff2f6; /* width:90%; */
    padding: 0 !important;
    border: 0 !important;
}
.ruleset_tag_input > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url("./images/dashboard_delete_normal.png") no-repeat;
    width: 19px;
    height: 19px;
}
.ruleset_tag_input > button:hover {
    background: url("./images/dashboard_delete_hover.png") no-repeat;
}
.ruleset_tag_input > button:active {
    background: url("./images/dashboard_delete_select.png") no-repeat;
}

.ruleset_tag_input > span > input[type="text"] {
    line-height: 28px;
    background: #eff2f6;
    width: 90%;
    padding: 0 !important;
    border: 0 !important;
}
.ruleset_tag_input > span > button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background: url("./images/dashboard_delete_normal.png") no-repeat;
    width: 19px;
    height: 19px;
}
.ruleset_tag_input > span > button:hover {
    background: url("./images/dashboard_delete_hover.png") no-repeat;
}
.ruleset_tag_input > span > button:active {
    background: url("./images/dashboard_delete_select.png") no-repeat;
}

#repeatTypeWrap {
    width: 224px;
    height: 160px;
    border: 1px solid #b4b9c3;
    background-color: #ffffff;
}

#repeatTypeWrap div {
    width: 30px;
    height: 27px;
    float: left;
    text-align: center;
    border: 1px solid #fcfcfc;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.repeatTypeMonCal:hover {
    background-color: #d9e0e7;
}
.repeatTypeMonCal.on {
    background-color: rgba(49, 150, 255, 0.59);
}

.selected_item_box_condition {
    height: 100px;
    width: 500px;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    margin: 5px 0;
    background-color: #ffffff;
    overflow-y: auto;
}
.selected_item_box_condition table th,
.selected_item_box_condition table td {
    padding: 2px 0 !important;
}
.selected_item_box_condition table th > span,
.selected_item_box_condition table td > span {
    padding-left: 4px;
}

.selected_item_box_condition .selected > span {
    width: 440px;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.ruleset_pop_list {
    border-left: 1px solid #dad4d4;
    border-right: 1px solid #dad4d4;
    border-bottom: 1px solid #dad4d4;
    border-top: 1px solid #dad4d4;
    border-bottom-left-radius: 2px;
    font-size: 13px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ruleset_pop_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    padding: 7px 10px 7px 10px;
    background: url(./images/table_divider.png) no-repeat right #eeeeef;
}

.ruleset_pop_list table tr td {
    height: 20px;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle;
    text-align: left;
    padding: 9px 10px 9px 10px;
    line-height: 20px;
    position: relative;
    color: #797979;
}

.selectedDeviceDelBtn {
    height: 19px;
    width: 19px;
    background: url("./images/tab_close_normal.png") no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}
.selectedDeviceDelBtn:hover {
    height: 19px;
    width: 19px;
    background: url("./images/tab_close_hover.png") no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}
.selectedDeviceDelBtn:active {
    height: 19px;
    width: 19px;
    background: url("./images/tab_close_select.png") no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}

.base.searchBtn {
    width: 30px;
    height: 30px;
    background: #fff url("./images/search_select.png") no-repeat 8px 7px !important;
}

.ruleset_curr_time .time_box {
    height: 120px;
    overflow-y: auto;
}

.rdg-cell {
    display: inline-block;
    position: absolute;
    height: inherit;
    padding: 0 8px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rdg-cell-frozen {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
}
.rdg-cell-frozen-last {
    box-shadow: 2px 0 5px -2px rgba(136, 136, 136, 0.3);
}
.react-contextmenu--visible {
    z-index: 1000;
}
.rdg-cell.readonly {
    background-color: #000;
}
.rdg-cell .form-control-feedback {
    color: #a94442;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1000000;
    display: block;
    width: 34px;
    height: 34px;
}
.rdg-cell.editing {
    padding: 0;
    overflow: visible !important;
}
.rdg-cell.editing .has-error input {
    border: 2px solid red !important;
    border-radius: 2px !important;
}
.rdg-cell-value ul {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
}
.rdg-cell-value .btn-sm {
    padding: 0;
}
.rdg-cell-expand {
    float: right;
    display: table;
    height: 100%;
}
.rdg-cell-expand > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}
.rdg-child-row-action-cross:before,
rdg-child-row-action-cross:after {
    content: "";
    position: absolute;
    background: grey;
    height: 50%;
}
.rdg-child-row-action-cross:before {
    left: 21px;
    width: 1px;
    height: 100%;
}
.rdg-child-row-action-cross:after {
    top: 50%;
    left: 20px;
    height: 1px;
    width: 15px;
    content: "";
    position: absolute;
    background: grey;
}
.rdg-child-row-action-cross:hover {
    background: red;
}
.rdg-child-row-btn {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 6px);
    height: 12px;
    width: 12px;
    z-index: 2;
    background: #fff;
}
.rdg-child-row-btn .MuiSvgIcon-root {
    font-size: 16px;
    color: grey;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
}
.rdg-child-row-btn .MuiSvgIcon-root:hover {
    color: red;
}
.rdg-cell-action {
    float: right;
    height: 100%;
}
.rdg-cell-action-last {
    margin-right: -8px;
}
.rdg-cell-action-button {
    width: 35px;
    height: 100%;
    text-align: center;
    position: relative;
    display: table;
    color: #4a9de2;
}
.rdg-cell-action-button > span {
    display: table-cell;
    vertical-align: middle;
}
.rdg-cell-action-button-toggled,
.rdg-cell-action-button:hover {
    color: #447bbb;
}
.rdg-cell-action-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    box-shadow: 0 0 3px 0 #ccc;
}
.rdg-cell-action-menu > span {
    display: block;
    padding: 3px 10px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.rdg-cell-action-menu > span:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
.rdg-cell-mask {
    position: absolute;
    pointer-events: none;
    outline: 0;
}
.rdg-filter-container {
    display: flex;
    height: inherit;
    align-items: center;
}
.rdg-filter {
    width: 100%;
    padding: 4px;
    font-size: 14px;
}
.rdg-filter-badge {
    cursor: help;
    padding-left: 4px;
}
.rdg-checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.rdg-checkbox-input {
    all: unset;
    width: 0;
    margin: 0;
}
.rdg-checkbox {
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    background: #fff;
}
.rdg-checkbox-input:checked + .rdg-checkbox {
    background: #005295;
    box-shadow: inset 0 0 0 4px #fff;
}
.rdg-checkbox-input:focus + .rdg-checkbox {
    border-color: #62b8ff;
}
.rdg-root {
    position: relative;
    z-index: 0;
    border: 1px solid #ddd;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
}
.rdg-root *,
.rdg-root ::after,
.rdg-root ::before {
    box-sizing: inherit;
}
.rdg-viewport {
    position: relative;
    overflow-x: auto;
    overflow-y: scroll;
    background-color: #fff;
    will-change: transform;
}
.rdg-grid {
    min-height: 1px;
}
.rdg-editor-container {
    position: absolute;
}
.rdg-select-editor,
.rdg-text-editor {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    padding: 1px 7px 0;
    margin: -1px 0 0 -1px;
    border: 2px solid #ccc;
    background-color: #fff;
    font-size: 14px;
    line-height: 1.2;
}
.rdg-select-editor::placeholder,
.rdg-text-editor::placeholder {
    color: #999;
    opacity: 1;
}
.rdg-select-editor:-ms-input-placeholder,
.rdg-text-editor:-ms-input-placeholder {
    color: #999;
}
.rdg-select-editor::-ms-input-placeholder,
.rdg-text-editor::-ms-input-placeholder {
    color: #999;
}
.rdg-select-editor:focus,
.rdg-text-editor:focus {
    border-color: #66afe9;
}
.rdg-header {
    background: #f9f9f9;
    overflow: hidden;
    font-weight: 700;
}
.rdg-header-row {
    position: relative;
    background-color: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rdg-header-cell-resizer {
    cursor: col-resize;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10px;
}
.rdg-cell .Select {
    max-height: 30px;
    font-size: 12px;
    font-weight: 400;
}
.rdg-cell .Select-control {
    max-height: 30px;
    border: 1px solid #ccc;
    color: #555;
    border-radius: 3px;
}
.rdg-cell .is-focused:not(.is-open) > .Select-control {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.rdg-cell .Select-control .Select-placeholder {
    line-height: 20px;
    color: #999;
    padding: 4px;
}
.rdg-cell .Select-control .Select-input {
    max-height: 28px;
    padding: 4px;
    margin-left: 0;
}
.rdg-cell .Select-control .Select-input input {
    padding: 0;
    height: 100%;
}
.rdg-cell .Select-control .Select-arrow-zone .Select-arrow {
    border-color: gray transparent transparent;
    border-width: 4px 4px 2.5px;
}
.rdg-cell .Select-control .Select-value {
    padding: 4px;
    line-height: 20px !important;
}
.rdg-cell .Select--multi .Select-control .Select-value {
    padding: 0;
    line-height: 16px !important;
    max-height: 20px;
}
.rdg-cell .Select--multi .Select-control .Select-value .Select-value-icon,
.rdg-cell .Select--multi .Select-control .Select-value .Select-value-label {
    max-height: 20px;
}
.rdg-cell .Select-control .Select-value .Select-value-label {
    color: #555 !important;
}
.rdg-cell .Select-menu-outer {
    z-index: 2;
}
.rdg-cell .Select-menu-outer .Select-option {
    padding: 4px;
    line-height: 20px;
}
.rdg-cell .Select-menu-outer .Select-menu .Select-option.is-focused,
.rdg-cell .Select-menu-outer .Select-menu .Select-option.is-selected {
    color: #555;
}
.rdg-header-sort-cell {
    cursor: pointer;
    display: flex;
}
.rdg-header-sort-name {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rdg-selected {
    border: 2px solid #66afe9;
}
.rdg-selected .drag-handle {
    pointer-events: auto;
    position: absolute;
    bottom: -5px;
    right: -4px;
    background: #66afe9;
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-right: 0;
    border-bottom: 0;
    cursor: crosshair;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
}
.rdg-selected:hover .drag-handle {
    bottom: -8px;
    right: -7px;
    background: #fff;
    width: 16px;
    height: 16px;
    border: 1px solid #66afe9;
}
.rdg-selected-range {
    border: 1px solid #66afe9;
    background-color: #66afe930;
}
.react-grid-cell-dragged-over-down,
.react-grid-cell-dragged-over-up {
    border: 1px dashed #000;
    background: rgba(0, 0, 255, 0.2) !important;
}
.react-grid-cell-dragged-over-up {
    border-bottom-width: 0;
}
.react-grid-cell-dragged-over-down {
    border-top-width: 0;
}
.react-grid-cell-copied {
    background: rgba(0, 0, 255, 0.2) !important;
}
.rdg-row {
    background-color: #fff;
}
.rdg-row:hover {
    background-color: #f5f5f5;
}
.rdg-row-selected {
    background-color: #dbecfa;
}
.rdg-row-selected:hover {
    background-color: #c9e3f8;
}
.rdg-row-expand-icon {
    cursor: pointer;
}
.rdg-row-expand-icon:hover {
    color: #777;
}
.rdg-row-group {
    padding: 0 8px;
    border-bottom: 1px solid #ddd;
}
.rdg-summary {
    border-top: 2px solid #aaa;
    position: relative;
    overflow: hidden;
}
.rdg-summary .rdg-row:last-child .rdg-cell {
    border-bottom-color: transparent;
}
