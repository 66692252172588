.user-info {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: row;
}

.login-info {
    width: 170px;
    height: 180px;
    text-align: center;
    position: relative;
}

.login-info .login-id {
    display: block;
    font-size: 10px;
    color: #363636;
    margin-top: 20px;
    position: relative;
    height: calc(100% - 20px);
}

.login-info .id {
    display: block;
    font-size: 20px;
    color: #6e78c8;
    font-weight: bold;
    padding-top: 4px;
    height: calc(100% - 20px);
    overflow: hidden;
    word-break: break-all;
}

.detail-info {
    width: calc(100% - 170px);
    height: 180px;
    display: inline-flex;
    flex-direction: column;
}

.detail-info .detail-item {
    height: 33px;
    display: inline-flex;
    flex-direction: column;
    margin-top: 20px;
}

.detail-info .detail-item .detail-title {
    display: block;
    font-size: 10px;
    color: #363636;
    font-weight: bold;
    height: 12px;
}

.detail-info .detail-item .detail-text {
    display: inline-block;
    font-size: 16px;
    color: #363636;
    height: 16px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}