.notice-info {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
}

.notice-row {
    height: 28px;
    display: inline-flex;
    flex-direction: row;
    margin: 3px 3px 3px 0;
}

.notice-row:hover {
    cursor: pointer;
}

.notice-row .import {
    float: right;
    width: 17px;
    height: 17px;
    background: url(../../images/icon/widget_ic_noti_import.png);
    margin-top: 4px;
    border: 0;
    outline: 0;
    margin-right: 10px;
}

.notice-row .title {
    color: #363636;
    font-size: 13px;
    width: 167px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notice-row .id {
    color: #363636;
    font-size: 13px;
    width: 60px;
    float: right;
    margin: 0px 10px;
    line-height: 28px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notice-row .date {
    color: #6e78c8;
    font-size: 13px;
    width: 75px;
    float: right;
    line-height: 28px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

