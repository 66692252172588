.thumbnail_view_wrap .thumbview_box .check span {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url('../../images/img/thumb_check.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60px;
}

.thumbnail_view_wrap .check {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
}

.thumbnail_view_wrap .thumbview_box.on .check {
    display: block;
}

.thumbnail_view_wrap .thumbview_box.device_thumb.landscape {
    display: table-cell;
}

.thumbnail_view_wrap .thumbview_box.device_thumb.landscape > div.dev_thumb_img_wrapper {
    width: 100%;
    height: 100%;
    background-color: #dddede;
    text-align: center;
    overflow: hidden;
}

.thumbnail_view_wrap .thumbview_box.device_thumb.portrait > div.dev_thumb_img_wrapper {
    width: 100%;
    height: 100%;
    background-color: #dddede;
    display: inline-block;
}

.thumbnail_view_wrap .thumbview_box.device_thumb.landscape > div.dev_thumb_img_wrapper > div {
    width: 100%;
    height: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: center;
}
.thumbnail_view_wrap .thumbview_box.device_thumb.portrait > div.dev_thumb_img_wrapper > div {
    width: 56%;
    height: 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position-y: center;
}

.thumbnail_view_wrap .device_info,
.device_tag_info {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../images/bg/thumb_layer_bg.png') repeat;
    color: #000;
    font-size: 12px;
    text-align: center; /* line-height:20px; */
    z-index: 100;
}
.thumbnail_view_wrap .device_info:hover {
    display: block;
}
.thumbnail_view_wrap .device_info strong {
    display: block;
    padding: 0 0 0 0;
}
.thumbnail_view_wrap .device_info span {
    color: #7f7f7f;
}
.thumbnail_view_wrap .device_info > div {
    display: table;
    width: 100%;
    height: 100%;
}
.thumbnail_view_wrap .device_info > div > div {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 10px;
}
.thumbnail_view_wrap .device_tag_info strong {
    display: block;
    padding: 0 0 0 0;
}
.thumbnail_view_wrap .device_tag_info span {
    color: #7f7f7f;
}
.thumbnail_view_wrap .device_tag_info > div {
    display: inline-block;
    width: 100%;
    height: 100%;
}
.thumbnail_view_wrap .device_tag_info > div > div {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 10px;
}
.thumbnail_view_wrap .device_tag_info > div i {
    display: inline-block;
    font-style: normal;
    color: #fff;
    background: #9aacbf;
    border: 1px solid #9aacbf;
    border-radius: 2px;
    margin-right: 8px;
    width: 26px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
}

.thumbnail_view_wrap .device_tag_info > div > span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.thumbnail_view_wrap .device_info.add span:first-child {
    display: inline-block;
    padding-top: 0px;
}
.thumbnail_view_wrap .device_info.add > div i {
    display: inline-block;
    font-style: normal;
    color: #fff;
    background: #9aacbf;
    border: 1px solid #9aacbf;
    border-radius: 2px;
    margin-right: 8px;
    width: 26px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 10px;
}
.thumbnail_view_wrap .device_info.add span {
    display: inline-block;
    padding-top: 2px;
}
.thumbnail_view_wrap .device_info span {
    color: #7f7f7f;
}
.thumbnail_view_wrap .device_info.add > div {
    height: 100%;
    text-align: left;
    vertical-align: middle;
}
.thumbnail_view_wrap .device_info.add {
    font-size: 8px;
}
.thumbnail_view_wrap .device_info > div {
    display: table;
    width: 100%;
    height: 100%;
}
.thumbnail_view_wrap .thumbview_box:hover .device_info {
    display: table;
}
.thumbnail_view_wrap .thumbview_box.tiny:hover .device_info {
    display: none;
}
.thumbnail_view_wrap .thumbview_box.information .device_info {
    display: table;
}
.thumbnail_view_wrap .thumbview_box.information.tiny .device_info {
    display: none;
}
.thumbnail_view_wrap .thumbview_box.tag_information .device_tag_info {
    display: block;
}
.thumbnail_view_wrap .thumbview_box.tag_information.tiny .device_tag_info {
    display: none;
}

.thumbnail_view_wrap > div > .status_wrap > div > .info_button:hover {
    background: url('../../images/icon/info_over.png.png') no-repeat right center;
}
.thumbnail_view_wrap > div > .status_wrap > div > .info_button {
    width: 22px;
    height: 22px;
    background: url('../../images/icon/info_normal.png.png') no-repeat right bottom;
    margin-top: -4px;
}

.thumbnail_view_wrap .status_wrap .child_info {
    position: absolute;
    display: inline-block;
    padding: 0 25px 0 5px;
    right: 7px;
    width: 40px;
    text-align: right;
    background-color: #4f4f4f;
    border-radius: 4px;
    line-height: 20px;
    background: url('../../images/icon/open_newtab.png') no-repeat right center;
}

.thumbnail_view_wrap .dev_img_thumb,
.dev_img_capture {
    background-position: center !important;
    background-size: contain !important;
}

.thumbnail_view_wrap .ov {
    z-index: 10;
}

.thumbnail_view_wrap .ov .overlay_info_box {
    padding: 13px 15px 9px 15px;
    text-align: left;
}

.thumbnail_view_wrap .ov .overlay_info_box span {
    margin: 0;
    color: #000;
    line-height: 16px;
}

.thumbnail_view_wrap .ov .overlay_info_box .float_r {
    width: 80px;
}

.thumbnail_view_wrap .ov .info {
    padding: 0 15px;
}

.thumbnail_view_wrap .ov .info p {
    margin: 0;
    color: #808080;
    font-size: 12px;
    line-height: 22px;
    text-align: left;
}
.thumbnail_view_wrap .ov .info p span {
    color: #000;
}

.thumbnail_view_wrap .link_cell:hover {
    color: blue;
    cursor: pointer;
}
