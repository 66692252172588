.organization_group_contents {
    width: 100%;
    display: inline-flex;
    flex-flow: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.organization_group_contents_name {
    width: 100%;
    height: 27px;
    display: inline-flex;
    flex-flow: row;
    line-height: 27px;
}
.organization_group_contents_title {
    width: 200px;
    font-size: 13px;
    color: #8d8d8d;
}
.organization_group_contents_search_btn {
    background: url(../../../images/icon/search_select.png) no-repeat 6px 6px;
    display: inline-block;
    height: 27px;
    width: 27px;
}
.organization_group_contents_list {
    width: 402px;
    height: 370px;
    display: inline-flex;
    flex-flow: column;
}
.organization_group_contents_row {
    height: 38px;
    line-height: 38px;
}

.organization_group_user_pop_wrap {
    overflow-y: auto;
    overflow-x: hidden;
    height: 458px;
}
.organization_group_detail_view {
    position: relative;
}
.organization_group_detail_view > table {
    width: 100%;
}
.organization_group_detail_view > table > tbody{
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
.organization_group_detail_view > table > tbody > tr{
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.organization_group_detail_view > table > tbody > th{
    font-size: 13px;
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}
.organization_group_detail_view_name{
    width: 200px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.organization_group_detail_view_input{
    width: 150px;
}