.container.bg {
    background: url( ../../images/bg/sign_in.png) repeat 379px 0;
    overflow-x: hidden;
    overflow-y: auto;
}
a {
    text-decoration: none;
    color: #818181;
}
input[type="text"], textarea {
    cursor: text !important;
}

.container {
    min-width: 1024px;
    min-height: 768px;
    position: absolute;
    height: 100%;
    width: 100%;
}
.sign_input.underline {
    border-bottom: 1px solid #a9adb2 !important;
}
.sign_input:hover {
    background: #38414c;
    border: 0 !important;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
}
.sign_input {
    height: 42px;
    font-size: 14px;
    background:
            #323b46;
    border: 0 !important;
    border-radius: 0 !important;
    width: 266px;
    color:
            #fff !important;
    padding: 0 10px 0 10px;
}
.signin_wrap {
    width: 379px;
    background:
            #323b46;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 100%;
    min-height: 961px;
}
.signin_wrap > form > div > span > img {
    margin-top: 85px;
    margin-left: 113px;
}
.signin_wrap > div {
    position: relative;
    height: 100%;
}

.signin_area {
    margin-left: 50px;
    margin-top: 167px;
}
.sign_btn {
    width: 276px;
    height: 42px;
    background:
            #4fa5ff;
    border: 0;
    font-size: 13px;
    color:
            #fff;
    margin: 51px 0 0 50px;
}
.signup_go_wrap {
    position: absolute;
    bottom: 67px;
    left: 50px;
    border-top: 1px solid
    #3e4751;
    width: 275px;
    color:
            #70767e;
    padding-top: 31px;
    line-height: 25px;
}
.checked_area {
    margin: 27px 0 0 50px;
}


.signup_go_wrap > a {
    color: #70767e;
}

.signup_go_wrap > a {
    color: #70767e;
}
.sign_mainimg_box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -135px;
    margin-left: -100px;
    z-index: 10;
}
.signup_wrap {
    background: #e6ebf0;
    top: 0;
    margin-left: 379px;
    bottom: 0;
    height:100%;
    min-height : 768px;
}
.signup_wrap > div {
    padding-left: 148px;
    padding-top: 115px;
    padding-bottom: 50px;
}
.signup_wrap h1 {
    font-size: 32px;
    color: #686b72;
    margin: 0;
    margin-bottom: 0px;
    line-height: 32px;
    margin-bottom: 25px;
}
.copyRight {
    height: 50px;
    text-align: center;
    right: 0px;
    line-height: 18px;
    display: block;
    padding-top: 10px;
}

.signup_checking_area {
    width: 605px;
    border-bottom: 1px solid #dfe3e8;
    padding-top: 40px;
    padding-bottom: 29px;
}

.signup_checking_area h3 {
    font-size: 18px;
    color: #686b72;
    font-weight: normal;
}

.signup_checking_area h3 > span {
    font-size: 12px;
    color: #8a8d90;
    display: inline-block;
    padding-left: 28px;
}

.signup_checking_area table th, .signup_checking_area table td {
    vertical-align: middle;
    padding: 6px 0 6px 3px;
}

.signup_checking_area table th, .signup_checking_area table td {
    vertical-align: middle;
    padding: 6px 0 6px 3px;
}

.signup_wrap h1 {
    font-size: 32px;
    color: #686b72;
    margin: 0;
    margin-bottom: 0px;
    line-height: 32px;
    margin-bottom: 25px;
}

.signup_wrap .signup {
    border: 0;
    font-size: 13px;
    width: 179px;
    line-height: 40px;
    background: #4fa5ff;
    color: #fff;
    margin-top: 45px;
}

.signup_wrap .cancel {
    border: 0;
    font-size: 13px;
    width: 179px;
    line-height: 38px;
    background: #ecf0f4;
    color: #636464;
    margin-top: 45px;
    border: 1px solid
    #c6ccd5;
}

.signup_wrap .error_txt3 {
    left: initial;
}