.gnb a {
    display: block;
    width: 100%;
    color: #b9bbbd;
    text-align: center;
    text-decoration: none;
}
.gnb a > span {
    display: block;
    height: 30px;
    width: 28px;
    background: url('../../images/img/gnb.png') no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    margin-left: 33px;
    margin-bottom: 8px;
}
.gnb a:hover {
    color: #fff;
}

.gnb a > .gnb01 {
    background-position: 0px 2px;
}
.gnb a:hover > .gnb01 {
    background: url('../../images/motion/content.gif') no-repeat;
}

.gnb a > .gnb02 {
    background-position: 0 -82px;
}
.gnb a:hover > .gnb02 {
    background: url('../../images/motion/playlist.gif') no-repeat;
}
.gnb a > .gnb03 {
    background-position: 0 -164px;
}
.gnb a:hover > .gnb03 {
    background: url('../../images/motion/schedule.gif') no-repeat;
}
.gnb a > .gnb04 {
    background-position: 0 -246px;
}
.gnb a:hover > .gnb04 {
    background: url('../../images/motion/device.gif') no-repeat;
}
.gnb a > .gnb05 {
    background-position: 0 -328px;
}
.gnb a:hover > .gnb05 {
    background: url('../../images/motion/statistics.gif') no-repeat;
}
.gnb a > .gnb06 {
    background-position: 0 -410px;
}
.gnb a:hover > .gnb06 {
    background: url('../../images/motion/user.gif') no-repeat;
}
.gnb a > .gnb07 {
    background-position: 0 -492px;
}
.gnb a:hover > .gnb07 {
    background: url('../../images/motion/setting.gif') no-repeat;
}

.gnb a > .gnb09 {
    background-position: 0 -694px;
}
.gnb a:hover > .gnb09 {background-position: -94px -694px;}

.gnb a > .gnb10 {
    background-position: 0 -776px;
}
.gnb a:hover > .gnb10 {
    background: url('../../images/motion/ruleset.gif') no-repeat;
}

.gnb a > .gnb11 {
    background-position: 0 -858px;
}

.gnb a:hover > .gnb11 {
    background: url('../../images/motion/insight.gif') no-repeat;
}


.gnb > ul > li.on > a > .gnb01 {background-position:-188px 2px;}
.gnb > ul > li.on > a > .gnb02 {background-position:-188px -82px;}
.gnb > ul > li.on > a > .gnb03 {background-position:-188px -164px;}
.gnb > ul > li.on > a > .gnb04 {background-position:-188px -246px;}
.gnb > ul > li.on > a > .gnb05 {background-position:-188px -328px;}
.gnb > ul > li.on > a > .gnb06 {background-position:-188px -410px;}
.gnb > ul > li.on > a > .gnb07 {background-position:-188px -492px;}
.gnb > ul > li.on > a > .gnb08 {background-position:-188px -612px;}
.gnb > ul > li.on > a > .gnb09 {background-position:-188px -694px;}
.gnb > ul > li.on > a > .gnb10 {background-position:-188px -776px;}
.gnb > ul > li.on > a > .gnb11 {background-position:-188px -858px;}

.gnb > ul > li.on > a:hover > .gnb01 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb02 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb03 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb04 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb05 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb06 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb07 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb08 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb10 {background-position:0px 0px;}
.gnb > ul > li.on > a:hover > .gnb11 {background-position:0px 0px;}
