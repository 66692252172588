.content_detail_view .ui-dialog-content {
    padding-bottom: 30px;
}

.content_detail_view .content_detail .whiteButton {
    border: 1px solid #dde0e5;
}

.icon {
    width: 24px;
    height: 24px;
    display: block;
    float: left;
    margin: 5px;
    cursor: pointer;
    background-size: cover;
}

.icon.icon-close {
    background: url('../../../images/button/popup_btn_circle_close_normal.png');
    cursor: pointer;
}

.icon.icon-minimize {
    background: url('../../../images/button/popup_btn_circle_hide_normal.png');
    cursor: pointer;
}

.icon.icon-maximize {

}

.icon.icon-restore {
    background: url('../../../images/button/popup_btn_circle_show_normal.png');
    cursor: pointer;
}

.playlist_imgview {
    float: left;
    color:
            #3d3d3d;
    position: relative;
}

.playlist_imgview > .preview_wrap {
    position: relative;
    width: 370px;
    overflow: hidden;
    background-color: #E4E4E4;
}

.playlist_detail {
    float: left;
    width: 430px;
}

.detail_view {
    position: relative;
}

.sub_tabwrap {
    width: 100%;
    height: 38px;
    position: relative;
    overflow: hidden;
    background: url('../../../images/bg/datetab_bg2.png') repeat-x bottom;
}

.error_txt2 {
    width: 100%;
    color:
            #f79394;
    line-height: 13px;
}
.detail_view .content_expiration_date input[type='text'] {
    width: 95px;
    display: inline;
}

.detail_view .content_expiration_date .react-datepicker-popper {
    position: fixed !important;
    top: 127px !important;
    left: 446px !important;
    display: block;
}

.detail_view .search_detail_calendar {
    position: relative;
    top: 3px;
    right: 25px;
    width: 22px;
    height: 22px;
    background: url('../../../images/icon/calander_icon_normal.png');
    border: 0;
}

.detail_view .search_detail_calendar:hover {
    cursor: pointer;
}

.detail_view .search_detail_calendar:disabled {
    cursor: default;
}

.error_text {
    color: #f79394;
    padding-top: 2px;
    line-height: 15px;
    white-space: normal;
}

