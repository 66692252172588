.rm-summary {
    width: 100%;
    height: 90px;
    display: inline-flex;
    flex-direction: row;
}

.rm-summary-total {
    width: 50%;
    height: 80px;
    padding-top: 10px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
    float: left;
    font-size: 32px;
    text-align: center;
    background: rgba(112,198,230,0.3);
    line-height: 55px;
    color: #363636;
}

.rm-summary-total > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.rm-summary-running {
    width: 50%;
    height: 80px;
    padding-top: 10px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    float: left;
    font-size: 32px;
    text-align: center;
    background: #70C6E6;
    line-height: 55px;
    color: #ffffff;
}

.rm-summary-running > span {
    font-size: 12px;
    display: block;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
}

.rm-server-list {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    margin-top: 10px;
    overflow-y: hidden;
    height: 76px;
}

.rm-server-list:hover {
    overflow-y: overlay;
}

.rm-server-row {
    height: 26px;
    display: inline-flex;
    flex-direction: row;
    color: #4f5a6d;
}

.rm-server-row .status {
    width: 9px;
    margin: 0px 9px;
    position: relative;
}

.rm-server-row .status .red {
    background: #ee392f;
    width: 9px;
    height: 9px;
    border-radius: 25px;
    position: absolute;
    top: calc(50% - 5px);
}

.rm-server-row .status .green {
    background: #1DA461;
    width: 9px;
    height: 9px;
    border-radius: 25px;
    position: absolute;
    top: calc(50% - 5px);
}

.rm-server-row .status .yellow {
    background: #F8A631;
    width: 9px;
    height: 9px;
    border-radius: 25px;
    position: absolute;
    top: calc(50% - 5px);
}

.rm-server-row .title {
    font-size: 13px;
    width: 142px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rm-server-row .id {
    font-size: 13px;
    width: 98px;
    float: right;
    margin: 0px 10px;
    line-height: 26px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rm-server-row .date {
    font-size: 12px;
    width: 63px;
    float: right;
    line-height: 26px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* width */
.rm-server-list::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.rm-server-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.rm-server-list::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 5px;
}

/* Handle on hover */
.rm-server-list::-webkit-scrollbar-thumb:hover {
    background: #999;
}